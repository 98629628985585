import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
	<Layout>
		<SEO title="Thank You Page" />
		<div className="thankyou">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-white text-center text-space">THANK YOU</h3>
						<h3 className="text-center text-white">
							<strong>
								Thank you for getting in touch with us. We will get back with
								you soon.&nbsp;
							</strong>
						</h3>
						<div className="text-center">
							<Link className="hbtn hb-fill-right-br" to="/">
								Go back to the homepage
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThankYou
